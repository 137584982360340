import { t } from "@lingui/macro";
import { ScrollArea, Separator } from "@reactive-resume/ui";
import { useRef } from "react";

import { Copyright } from "@/client/components/copyright";
import { ThemeSwitch } from "@/client/components/theme-switch";

import { ExportSection } from "./sections/export";
import { InformationSection } from "./sections/information";
import { LayoutSection } from "./sections/layout";
import { NotesSection } from "./sections/notes";
import { PageSection } from "./sections/page";
import { SharingSection } from "./sections/sharing";
import { StatisticsSection } from "./sections/statistics";
import { TemplateSection } from "./sections/template";
import { ThemeSection } from "./sections/theme";
import { TypographySection } from "./sections/typography";
import { SectionIcon } from "./shared/section-icon";

export const RightSidebar = () => {
  const containterRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoView = (selector: string) => {
    const section = containterRef.current?.querySelector(selector);
    section?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex bg-secondary-accent/30">
      <ScrollArea orientation="vertical" className="h-screen flex-1 pb-16 lg:pb-0" style={{boxShadow:'1px 1px 5px grey'}}>
        <div ref={containterRef} className="grid gap-y-6 p-6 @container/right border-l-2 border-gray-400" style={{boxShadow:'2px 2px 5px grey'}}>
          <TemplateSection />
          <Separator />
          <LayoutSection />
          <Separator />
          <TypographySection />
          <Separator />
          <ThemeSection />
          <Separator />
          <PageSection />
          <Separator />
          <ExportSection />
          <Separator />
          {/* <Copyright className="text-center" /> */}
        </div>
      </ScrollArea>

      <div className="hidden basis-12 flex-col items-center justify-between !bg-#09b48b-600 py-4 sm:flex" >
        <div />

        <div className="flex flex-col items-center justify-center gap-y-2">
          <SectionIcon
            id="template"
            name={t`Template`}
            onClick={() => scrollIntoView("#template")}
          />
          <SectionIcon id="layout" name={t`Layout`} onClick={() => scrollIntoView("#layout")} />
          <SectionIcon
            id="typography"
            name={t`Typography`}
            onClick={() => scrollIntoView("#typography")}
          />
          <SectionIcon id="theme" name={t`Theme`} onClick={() => scrollIntoView("#theme")} />
          <SectionIcon id="page" name={t`Page`} onClick={() => scrollIntoView("#page")} />
          <SectionIcon id="export" name={t`Export`} onClick={() => scrollIntoView("#export")} />
        
        </div>

        <ThemeSwitch size={14} />
      </div>
    </div>
  );
};
