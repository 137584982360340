/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import { List, SquaresFour } from "@phosphor-icons/react";
import { ScrollArea, Tabs, TabsContent, TabsList, TabsTrigger } from "@reactive-resume/ui";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useMyStore } from "@/client/stores/resumelist";

import Navbar from "../_components/navbar";
import { GridView } from "./_layouts/grid";
import { ListView } from "./_layouts/list";
type Layout = "grid" | "list";

export const ResumesPage = () => {
  const [layout, setLayout] = useState<Layout>("grid");
  const { items, addItem } = useMyStore();
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the value of a specific query parameter by its name
    const paramValue: any = urlParams.get("q");
    if (paramValue) {
      // Example usage:
      console.log(paramValue, "params");

      const jsonString = atob(paramValue);
      console.log(jsonString, "jstr");
      console.log(typeof jsonString, "typ");
      const resume = JSON.parse(jsonString);
      // Parse the JSON string

      const randomId = uuidv4();

      // Get the current date and time
      const currentDateTime = dayjs();

      // Format the date and time in ISO 8601 format
      const formattedDateTime = currentDateTime.toISOString();

      addItem(resume);
      navigate(`/products/ai-resume/builder/${resume?.id}`);
    } else {
      console.log("no params");
    }

    // This will print the value of the query parameter 'paramName' to the console
  }, []);
  return (
    <>
      <Helmet>
        <title>AI Resume Builder App | Create Professional Resumes Effortlessly</title>
      </Helmet>

      <Tabs
        value={layout}
        onValueChange={(value) => setLayout(value as Layout)}
        className="space-y-4"
      >
        <div className="flex items-center justify-between overflow-hidden">
          <motion.h1
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            className="text-4xl font-bold tracking-tight"
          >
            {t`Resumes`}
          </motion.h1>

          <TabsList>
            <TabsTrigger value="grid" className="size-8 p-0 sm:h-8 sm:w-auto sm:px-4">
              <SquaresFour />
              <span className="ml-2 hidden sm:block">{t`Grid`}</span>
            </TabsTrigger>
            <TabsTrigger value="list" className="size-8 p-0 sm:h-8 sm:w-auto sm:px-4">
              <List />
              <span className="ml-2 hidden sm:block">{t`List`}</span>
            </TabsTrigger>
          </TabsList>
        </div>

        <TabsContent value="grid">
          <GridView />
        </TabsContent>
        <TabsContent value="list">
          <ListView />
        </TabsContent>
      </Tabs>
    </>
  );
};
