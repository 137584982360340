import { t } from "@lingui/macro";
import { AspectRatio } from "@reactive-resume/ui";
import { cn, templatesList } from "@reactive-resume/utils";
import { motion } from "framer-motion";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useResumeStore } from "@/client/stores/resume";
import { useState } from "react";
import { getSectionIcon } from "../shared/section-icon";
import "./templateImage.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const TemplateSection = () => {
  const setValue = useResumeStore((state) => state.setValue);
  const currentTemplate = useResumeStore((state) => state?.resume?.data?.metadata?.template);

  // State to track the selected template
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const handleOpen = (template: any) => {
    setSelectedTemplate(template);
    setOpen(true);
  };

  // State to control the modal visibility
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <section id="template" className="grid gap-y-6">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-x-4">
          {getSectionIcon("template")}
          <h2
            className="line-clamp-1 text-3xl font-bold"
            style={{ color: "#09b48b" }}
          >{t`Template`}</h2>
        </div>
      </header>

      <main className="grid grid-cols-2 gap-5 @lg/right:grid-cols-3 @2xl/right:grid-cols-4">
        {templatesList.map((template, index) => (
          <AspectRatio key={template} ratio={1 / 1.4142}>
            <motion.div
              initial={{ opacity: 1 }}
              // whileHover={{ opacity: 0.4 }} // Apply fade effect to the template image
              className="relative cursor-pointer rounded-sm"
            >
              {/* Template Image */}
              <div className="relative group">
                <img
                  src={`/templates/jpg/${template}.jpg`}
                  alt={template}
                  className="block w-full h-auto transition-filter duration-300"
                />
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 filter blur-sm transition-filter duration-300"></div>
              </div>

              {/* Overlay for buttons */}
              <div style={{
                backdropFilter:"brightness(0.5)"
              }} className="absolute inset-0 flex items-center justify-center gap-2 opacity-0 hover:opacity-100 transition-opacity duration-300">
                {/* Use Button */}
                <button
                  className="rounded-full bg-#09b48b text-white px-2 py-2"
                  style={{
                    backgroundColor: "#09b48b",
                    color: "white",
                    borderRadius: "10px",
                    opacity: "1",
                  }}
                  onClick={() => {
                    setValue("metadata.template", template);
                  }}
                >
                  Use
                </button>
                {/* View Button */}
                <button
                  className="rounded-full bg-#09b48b text-white px-2 py-2"
                  style={{
                    backgroundColor: "#09b48b",
                    color: "white",
                    borderRadius: "10px",
                    opacity: "1",
                  }}
                  onClick={() => {
                    handleOpen(template);
                  }}
                >
                  View
                </button>
              </div>
            </motion.div>
          </AspectRatio>
        ))}
      </main>

      {/* Modal to display the selected template */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {selectedTemplate && (
            <img
              src={`/templates/jpg/${selectedTemplate}.jpg`}
              alt={selectedTemplate}
              className="rounded-sm"
              style={{ height: "70 vh", width: "50vw" }}
            />
          )}
        </Box>
      </Modal>
    </section>
  );
};
