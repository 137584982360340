import { SidebarSimple } from "@phosphor-icons/react";
import { Button, Sheet, SheetClose, SheetContent, SheetTrigger } from "@reactive-resume/ui";
// import { motion } from "framer-motion";
import { useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "../../components/header/Header";
// import Navbar from "./_components/navbar";
// import NavbarItem from "./_components/navbar";
import { Sidebar } from "./_components/sidebar";
export const DashboardLayout = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Header />
      <div className="top-0 z-50  flex items-center justify-between p-4 pb-0 lg:hidden">
        <Sheet open={open} onOpenChange={setOpen}>
          <SheetTrigger asChild>
            {/* <Button size="icon" variant="ghost" className="bg-background">
              <SidebarSimple />
            </Button> */}
          </SheetTrigger>

          <SheetContent showClose={false} side="left" className="focus-visible:outline-none">
            <SheetClose asChild className="absolute left-4 top-4">
              <Button size="icon" variant="ghost">
                <SidebarSimple />
              </Button>
            </SheetClose>

            <Sidebar setOpen={setOpen} />
          </SheetContent>
        </Sheet>
      </div>

      <main className="mx-6 my-4 lg:mx-8">
        <Outlet />
      </main>
    </div>
  );
};
