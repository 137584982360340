/* eslint-disable lingui/no-unlocalized-strings */
import { t } from "@lingui/macro";
import { ScrollArea, Separator } from "@reactive-resume/ui";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

import { OpenAISettings } from "./_sections/openai";

export const SettingsPage = () => (
  <div className="flex justify-center items-center h-screen">
    {" "}
    {/* Added flex utilities to center align */}
    <>
      <Helmet>
        <title>AI Resume Builder App | Create Professional Resumes Effortlessly</title>
      </Helmet>

      <div className="max-w-2xl space-y-4">
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          className="text-4xl font-bold tracking-tight"
        >
          {t`Open AI Settings`}
        </motion.h1>

        <ScrollArea hideScrollbar className="h-[calc(100vh-140px)] lg:h-[calc(100vh-88px)]">
          <div className="space-y-6">
            <OpenAISettings />
            <Separator />
          </div>
        </ScrollArea>
      </div>
    </>
  </div>
);

export default SettingsPage;
