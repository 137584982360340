import { UrlDto } from "@reactive-resume/dto";
import { useQuery } from "@tanstack/react-query";

import { RESUME_PREVIEW_KEY } from "@/client/constants/query-keys";
import { axios } from "@/client/libs/axios";

export const previewResume = async (data: { id: string; resume: object }) => {
  const response = await axios.post<UrlDto>(`/resume/print/${data.id}/preview`, {
    data: { resume: data.resume },
  });

  return response.data;
};

export const useResumePreview = (id: string, resume: object) => {
  const {
    error,
    isPending: loading,
    data,
  } = useQuery({
    queryKey: [RESUME_PREVIEW_KEY, { id }, resume],
    queryFn: () => previewResume({ id, resume }),
  });

  return { url: data?.url, loading, error };
};
