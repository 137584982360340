/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import Logo from "assets/images/Header/logo.svg";
// import Hamburger from "assets/images/Header/hamburger.svg";
// import Close from "assets/images/Header/close.svg";

import "./header.scss";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// import div from "./div.tsx";
import {
  ArrowDown,
  DescriptionScanner,
  InterviewService,
  Jobpost,
  LinkOptimization,
  ResumeBuilder,
  ResumeChecker,
  ResumeJD,
  ResumeScout,
  TalentSelector,
} from "../../constants/svgLogos";
import FadeDown from "../fade-down";
import FadeUp from "../fade-up";
import Tag from "../tag/Tag";

const ProductPopup = ({
  popupListData1,
  popupListData2,
  popupListData3,
  navigate,
  closeSideBar,
  setDropdown,
  sideMenu,
}: {
  popupListData1: any;
  popupListData2: any;
  popupListData3: any;
  navigate: any;
  closeSideBar: any;
  setDropdown: any;
  sideMenu: any;
}) => {
  return (
    <div className="headerProductPopup">
      <div className="headerProductPopupContainer">
        <div>
          {sideMenu ? (
            // eslint-disable-next-line lingui/no-unlocalized-strings
            <h2>
              Platform{" "}
              <span className="TagContiner">
                {" "}
                <Tag text="Organization" color="red"></Tag>
              </span>
            </h2>
          ) : (
            <div style={{ display: "flex" }}>
              <div>
                <h2>Platform </h2>
              </div>
              <div style={{ margin: "20px 5px" }}>
                <Tag text="Organization" color="red"></Tag>
              </div>
            </div>
          )}
          <ul>
            {popupListData1.map(
              (
                data: {
                  location: any;
                  img:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                  service:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                  feature:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                },
                i: React.Key | null | undefined,
              ) => (
                <li
                  className="headerProductPopupList"
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // eslint-disable-next-line
                    if (typeof window !== undefined) {
                      // navigate(data.location);
                     // window.open(`${data.location}`, "_blank");
                       window.location.href = `${data.location}`;
                    }
                    closeSideBar();
                    setDropdown(false);
                  }}
                >
                  <div>{data.img}</div>
                  <div className="popupList">
                    <span>{data.service}</span>
                    <p>{data.feature}</p>
                  </div>
                </li>
              ),
            )}
          </ul>
        </div>
        <div>
          {sideMenu ? (
            <h2>
              Recruiters{" "}
              <span className="TagContiner">
                {" "}
                <Tag text="Free" color="green"></Tag>
              </span>
            </h2>
          ) : (
            <div style={{ display: "flex" }}>
              <div>
                <h2>Recruiters </h2>
              </div>
              <div style={{ margin: "20px 5px" }}>
                <Tag text="Free" color="green"></Tag>
              </div>
            </div>
          )}
          <ul>
            {popupListData2.map(
              (
                data: {
                  location: any;
                  img:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                  service:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                  feature:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                },
                i: React.Key | null | undefined,
              ) => (
                <li
                  className="headerProductPopupList"
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // eslint-disable-next-line
                    if (typeof window !== undefined) {
                      // navigate(data.location);
                       window.location.href = `${data.location}`;
                     // window.open(`${data.location}`, "_blank");
                    }
                    closeSideBar();
                    setDropdown(false);
                  }}
                >
                  <div>{data.img}</div>
                  <div className="popupList">
                    <span>{data.service}</span>
                    <p>{data.feature}</p>
                  </div>
                </li>
              ),
            )}
          </ul>
        </div>
        <div>
          {sideMenu ? (
            <h2>
              Candidates{" "}
              <span className="TagContiner">
                {" "}
                <Tag text="Free" color="green"></Tag>
              </span>
            </h2>
          ) : (
            <div style={{ display: "flex" }}>
              <div>
                <h2>Candidates </h2>
              </div>
              <div style={{ margin: "20px 5px" }}>
                {/* <Tag color="#00B788">paid</Tag> */}
                <Tag text="Free" color="green"></Tag>
              </div>
            </div>
          )}
          <ul>
            {popupListData3.map(
              (
                data: {
                  location: any;
                  img:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                  service:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                  feature:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal
                    | null
                    | undefined;
                },
                i: React.Key | null | undefined,
              ) => (
                <li
                  className="headerProductPopupList"
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // eslint-disable-next-line
                    if (typeof window !== undefined) {
                      // navigate(data.location);
                       window.location.href = `${data.location}`;
                    //  window.open(`${data.location}`, "_blank");
                    }
                    closeSideBar();
                    setDropdown(false);
                  }}
                >
                  <div>{data.img}</div>
                  <div className="popupList">
                    <span>{data.service}</span>
                    <p>{data.feature}</p>
                  </div>
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const index = () => {
  const navigate = useNavigate();
  const popupListData1 = [
    {
      img: <InterviewService />,
      service: "Interview As A Service",
      feature: "Our AI powered intelligence platform to hire candidates",
      location: "https://quickrecruit.com/products/interview-as-a-service",
    },
  ];
  const popupListData2 = [
    {
      img: <Jobpost />,
      service: "Job Posting Management",
      feature: "Manage postings across social media platforms from one place",
      location: "https://quickrecruit.com/products/job-posting-management",
    },
    {
      img: <DescriptionScanner />,
      service: "Job Description Scanner",
      feature: "Enhance job descriptions to attract more candidates",
      location: "https://quickrecruit.com/products/job-description-scanner",
    },
    {
      img: <TalentSelector />,
      service: "Top Talent Selector",
      feature: "Get top resumes matching with your job description",
      location: "https://quickrecruit.com/products/top-talent-selector",
    },
    {
      img: <ResumeScout />,
      service: "Resume Data Scout",
      feature: "Visualise and validate candidates resumes",
      location: "https://quickrecruit.com/products/resume-data-scout",
    },
  ];
  const popupListData3 = [
    {
      img: <ResumeChecker />,
      service: "ATS Resume Checker",
      feature: "Optimize your resume and get personalised recommendations",
      location: "https://quickrecruit.com/products/ats-resume-checker",
    },
    {
      img: <ResumeBuilder />,
      service: "AI Resume Builder",
      feature: "Build your resume with top quality templates",
      location: "https://quickrecruit.com/products/ai-resume-builder",
    },
    {
      img: <LinkOptimization />,
      service: "AI Linkedin Optimization",
      feature: "Get more reach by optimizing your linkedIn profile",
      location: "https://quickrecruit.com/products/ai-linkedin-optimization",
    },
    {
      img: <ResumeJD />,
      service: "Resume-JD Syncer",
      feature: "Match your resume with job description",
      location: "https://quickrecruit.com/products/resume-jd-syncer",
    },
  ];

  const [dropdown, setDropdown] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMouseLeave, setIsMouseLeave] = useState(false);
  // const [impact, setImpact] = useState(true);
  // const [solution, setSolution] = useState(true);

  const dropdownActive = () => {
    setDropdown(!dropdown);
    setIsMouseLeave(false);
    // setDropdown(true);
  };

  useEffect(() => {
    if (window.innerWidth < 992) {
      setDropdown(true);
    }

    const resizeWindow = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    console.log("dropdown : ", dropdown);
  }, [dropdown]);

  const onMouseLeave = () => {
    setDropdown(false);
    setIsMouseLeave(true);
  };

  const sideBar = () => {
    setSideMenu(!sideMenu);
    setDropdown(true);
  };

  function closeSideBar() {
    setSideMenu(false);
  }

  useEffect(() => {
    if (sideMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // console.log("SideMenu : ", sideMenu);
  }, [sideMenu]);

  return (
    // <header className="header animate__animated animate__fadeInDown">
    <header className="header">
      <div style={{ fontSize: "10px" }} className="container headerContainer">
        <div className="headerLogo">
          {/* <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link> */}
          <a href="/">
            <img src="https://dj4gk1hnrapgw.cloudfront.net/logo.svg" alt="Logo" />
          </a>
        </div>
        <ul
          className="headerList"
          style={{
            right: sideMenu ? "0" : "-100%",
            transition: "all 0.5s ease",
          }}
        >
          <li
            className="headerProduct"
            onClick={() => setSideMenu(false)}
            onMouseEnter={() => {
              windowWidth > 992 && dropdownActive();
            }}
            onMouseLeave={() => {
              windowWidth > 992 && onMouseLeave();
            }}
          >
            <Link to="#">
              Products
              {dropdown ? (
                <FadeUp
                  duration={"0.5"}
                  position={windowWidth > 992 && true}
                  left={
                    windowWidth > 1150
                      ? "15%"
                      : windowWidth > 1010
                        ? "3%"
                        : windowWidth > 992
                          ? "1%"
                          : ""
                  }
                >
                  <ProductPopup
                    popupListData1={popupListData1}
                    popupListData2={popupListData2}
                    popupListData3={popupListData3}
                    navigate={navigate}
                    closeSideBar={closeSideBar}
                    setDropdown={setDropdown}
                    sideMenu={sideMenu}
                  />
                </FadeUp>
              ) : (
                isMouseLeave &&
                windowWidth > 992 && (
                  <FadeDown
                    left={
                      windowWidth > 1150
                        ? "15%"
                        : windowWidth > 1010
                          ? "3%"
                          : windowWidth > 992
                            ? "1%"
                            : ""
                    }
                  >
                    <div onMouseEnter={() => setDropdown(false)}>
                      <ProductPopup
                        popupListData1={popupListData1}
                        popupListData2={popupListData2}
                        popupListData3={popupListData3}
                        navigate={navigate}
                        closeSideBar={closeSideBar}
                        setDropdown={setDropdown}
                        sideMenu={sideMenu}
                      />
                    </div>
                  </FadeDown>
                )
              )}
            </Link>

            <div
              className="arrowDown"
              style={{
                transform: dropdown ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.5s ease",
              }}
            >
              <ArrowDown />
            </div>
          </li>
          <li onClick={() => setSideMenu(false)}>
            {/* <Link to="https://quickrecruit.com/integration">Integration</Link> */}
            <a href="https://quickrecruit.com/integration" >
              Integration
            </a>
          </li>
          <li onClick={() => setSideMenu(false)}>
            {/* <Link to="https://quickrecruit.com/pricing">Pricing</Link> */}
            <a href="https://quickrecruit.com/pricing" >
              Pricing
            </a>
          </li>
          <li onClick={() => setSideMenu(false)}>
            {/* <Link to="https://quickrecruit.com/contact-us">Contact</Link> */}
            <a href="https://quickrecruit.com/contact-us" >
              Contact
            </a>
          </li>
          {/* <li onClick={() => setSideMenu(false)}>
            <a href="/blogs">Blogs</a>
          </li> */}
          {/* <li onClick={() => setSideMenu(false)}>
            <Link to="/product">Suits</Link>
          </li> */}
        </ul>
        <div className="loginSignUp">
          <Link to="https://app.quickrecruit.com/signin">
            <button className="headerLogIn">Log In</button>
          </Link>
          <Link to="https://app.quickrecruit.com/signup">
            <button className="commonBtn headerSignUp">Get Started</button>
          </Link>
          <img
            className="headerHamburger"
            onClick={sideBar}
            src={
              sideMenu
                ? "https://dj4gk1hnrapgw.cloudfront.net/header_close.svg"
                : "https://dj4gk1hnrapgw.cloudfront.net/header_hamburger.svg"
            }
            alt="Hamburger"
          />
        </div>
      </div>
    </header>
  );
};

export default index;
