import "./Tag.css"; // You can style your tag component in this file

import React, { useState } from "react";

interface TagProps {
  text: string;
  color?: string;
  removable?: boolean;
  onRemove?: () => void;
}

const Tag: React.FC<TagProps> = ({ text, color, removable = false, onRemove }) => {
  const [hovered, setHovered] = useState(false);
  let bgColor, borderColor;
  if (color === "red") {
    color = "#cf1322";
    borderColor = "#ffa39e";
    bgColor = "#fff1f0";
  } else if (color === "green") {
    color = "#389e0d";
    borderColor = "#b7eb8f";
    bgColor = "#f6ffed";
  }

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleRemoveClick = () => {
    if (onRemove) {
      onRemove();
    }
  };

  return (
    <div
      className={`tag ${hovered && removable ? "tag-hovered" : ""}`}
      style={{ backgroundColor: bgColor, borderColor: borderColor, color: color }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span>{text}</span>
    </div>
  );
};

export default Tag;
