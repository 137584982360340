import * as SliderPrimitive from "@radix-ui/react-slider";
import { cn } from "@reactive-resume/utils";
import { forwardRef } from "react";

export const Slider = forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn("relative flex w-full touch-none select-none items-center", className)}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-2.5 w-full grow overflow-hidden rounded-full bg-#09b48b"> {/* Update color here */}
      <SliderPrimitive.Range className="absolute h-full" style={{backgroundColor:'#09b48b' }} />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="block size-5 rounded-full border-2 border-#09b48b bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-#09b48b focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" /> {/* Update color here */}
  </SliderPrimitive.Root>
));

Slider.displayName = SliderPrimitive.Root.displayName;
