import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import dayjs from 'dayjs';
// Define the type for your state
interface MyState {
  items: { id: number; title: string;slug:string;visibility:string;locked:boolean;userId:string;createdAt:string;updatedAt:string; data: Record<string, unknown> }[];
  addItem: (item: { id: number; name: string }) => void;
}

// Create your Zustand store
export const useMyStore = create<any>(
  // Use the curried version of create<T> for TypeScript
  devtools(
    persist(
      (set) => ({
        items: [], // Initialize your array of objects
        updateItem: (itemId:any, updatedData:any) => {
            set((state:any) => ({
              items: state.items.map((item:any) =>
                item.id === itemId ? { ...item, data: updatedData } : item
              ),
            }));
          },
        addItem: (item:any) => 
        {
            set((state:any) => ({
                  items: [...state.items, { ...item }], // Add the new item with an empty resumedata object
              }));
             }} ),
      {
        name: 'myStore', // Specify a name for local storage
        getStorage: () => localStorage, // Choose your storage (localStorage, sessionStorage, etc.)

            }
    )
  )
);


export const updateItem = (itemId: any, updatedData: any) => {
    const currentDateTime = dayjs();

// Format the date and time in ISO 8601 format
const formattedDateTime = currentDateTime.toISOString();

    updatedData.updatedAt=formattedDateTime;
    console.log('update2')
    useMyStore.setState((state:any) => ({
      items: state.items.map((item:any) =>
        item.id === itemId ? updatedData : item
      ),
    }));
  };

  export const deleteItem = (itemId: any) => {
    useMyStore.setState((state: any) => ({
        items: state.items.filter((item: any) => item.id !== itemId),
    }));
};
